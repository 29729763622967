import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Footer from '../Footer/Footer'
import { Container } from '@mui/material'
import { getModelHomeCards } from '../../services/api'
import { Link } from 'react-router-dom'
// import { LiaSpinnerSolid } from "react-icons/lia";
import './Model.css'
import Loader from '../../assets/aiModels/QLoader.svg'


const ModelHome = () => {

    const [cardData, setCardData] = useState([])

    useEffect(() => {
        (async function populateModelHomeCardList() {
            try {
                const response = await getModelHomeCards()
                setCardData(response.data.data)

            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className="ml-[90px]"><Navbar className='flex-shrink-0 fixed w-full' /></div>

                {/* page content */}
                <div className="ml-[90px] px-2 pt-[84px] mb-24">
                    {/* Your main content goes here */}
                    <Container sx={{ maxWidth: { xs: '100%', sm: '1252px' } }}>

                        {/* Breadcrumbs */}
                        <div className='flex justify-between py-4'>
                            <div>
                                <div className="flex items-center">
                                    <h2 className="text-2xl font-bold text-heading-black">Model Studio</h2>
                                </div>
                                <p className="text-base font-normal text-gray-70 pt-1">Train, Tune , Deploy AI models.</p>
                            </div>
                        </div>

                        {/* Card Wrapper */}
                        {cardData.length === 0 &&
                            <div className="min-h-[600px] w-full bg-white px-8 pb-8 shadow-md flex justify-center">
                                {/* <LiaSpinnerSolid className='animate-spin text-gen-color text-5xl duration-[10000ms] mt-40' /> */}
                                <img src={Loader} className='h-fit animate-spin text-gen-color text-5xl duration-[100000ms] mt-40' alt="" />
                            </div>
                        }
                        {cardData.length > 0 &&
                            <div className="min-h-[600px] w-full bg-white px-8 pb-8 shadow-md">
                                <div className="flex items-center">
                                    <p className='text-[20px] font-[700] py-4'>AI Models &nbsp;</p>
                                    <p className='text-[14px] text-[#10001A99] font-[500] py-4'>| {cardData.length} categories</p>
                                </div>
                                <p className='text-[16px] font-[500] mb-4 -mt-3'>Unlock a world of AI possibilities! Try For Free, then explore fascinating projects for<span className='text-[#008B16] font-[600]'> just $5.</span> Design logos, write code, and more for <span className='text-[#008B16] font-[600]'>only $0.01 per request</span> – that's 500 requests for <span className='text-[#008B16] font-[600]'>$5.</span></p>

                                <div className='flex flex-wrap gap-5'>
                                    {cardData.length > 0 &&
                                        cardData.map((item, key) => {
                                            return (
                                                <Link key={key} to={`/modelStudio/${item.categories}`}>
                                                    <div key={key}
                                                        className='h-[200px] w-[270px]  rounded-lg relative'
                                                    >
                                                        <img src={item.image_url} alt="" />
                                                        <div className='absolute bottom-0 h-[42px] w-full bg-black bg-opacity-50 rounded-b-lg flex justify-center items-center'>
                                                            <p className='text-white'>{item.categories}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }

                    </Container>
                </div>
                <div className={`ml-[90px]`}><Footer /></div>
            </div >
        </div >
    )
}

export default ModelHome