import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import ConnectModal from "./ConnectModal";
import axiosInstance from "../../../api/axios";
import { useNavigate } from "react-router-dom";

const FineTuneModelModal = ({ isOpen, setIsOpen, handleOutputData }) => {
  const [currentModal, setCurrentModal] = useState("main");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        "/nocode/list_csv_files"
      );
      if (response.data.success) {
        setFiles(Array.isArray(response.data.data) ? response.data.data : []);
        // Ensure response.data.data is an array
      } else {
        setError("Failed to fetch CSV files");
      }
    } catch (err) {
      setError("An error occurred while fetching CSV files");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Component mounted, calling API...");
    fetchFiles();
  }, []);

  // Effect for redirecting after 1 seconds when files.length === 0
  useEffect(() => {
    if (!loading && files.length === 0 && !error) {
      const timer = setTimeout(() => {
        navigate("/userData");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [files, loading, error, navigate]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const openConnectModal = () => {
    if (selectedFile) {
      setCurrentModal("connect");
    } else {
      alert("Please select a file.");
    }
  };

  const goBackToMainModal = () => {
    setCurrentModal("main");
  };

  return (
    <div>
      {isOpen && currentModal === "main" && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-4">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Modal Content */}
          <div className="relative mx-auto my-auto max-w-3xl w-full z-50">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* Modal Header */}
              <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                <h4 className="text-lg font-semibold">Upload</h4>
                <button
                  className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  <MdClose className="w-5 h-5" />
                </button>
              </div>

              {/* Table */}
              <div className="overflow-x-auto p-4">
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : Array.isArray(files) && files.length === 0 ? (
                  <p className="font-semibold">
                    You have not uploaded any CSV file in the storage. To continue, please upload a CSV file to the storage. Redirecting to Storage.
                  </p>
                ) : Array.isArray(files) ? (
                  <table className="min-w-full border rounded-md pb-4">
                    <thead className="bg-[#EFF0F8CC]">
                      <tr className="text-left text-sm text-[#10001A]">
                        <th className="py-2 px-4">File Name</th>
                        <th className="py-2 px-4">Type</th>
                        <th className="py-2 px-4">Size</th>
                        <th className="py-2 px-4">Last Modification</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files.map((file, index) => (
                        <tr
                          key={index}
                          className={`bg-white hover:bg-gray-100 text-[#10001A] ${
                            index % 2 === 1 ? "bg-[#F8F9FD80]" : ""
                          }`}
                          onClick={() => handleFileSelect(file)}
                        >
                          <td className="py-3 px-4 flex items-center">
                            <input
                              type="radio"
                              name="selectedFile"
                              className="mr-2"
                              checked={selectedFile === file}
                              onChange={() => handleFileSelect(file)}
                            />
                            <span className="text-sm">{file.file_name}</span>
                          </td>
                          <td className="py-2 px-4 text-sm text-gray-700">
                            {file.type}
                          </td>
                          <td className="py-2 px-4 text-sm text-gray-700">
                            {file.size}
                          </td>
                          <td className="py-2 px-4 text-sm text-gray-700">
                            {file.last_modified}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>You have not Uploaded any file yet. Redirecting...</p>
                )}
              </div>
              <div className="flex justify-end p-4">
                {Array.isArray(files) && files.length > 0 && (
                  <button
                    className="classic-button h-[48px] w-[120px] text-[18px] font-[600]"
                    onClick={openConnectModal}
                  >
                    Insert
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ConnectModal */}
      {isOpen && currentModal === "connect" && (
        <ConnectModal
          goBack={goBackToMainModal}
          setIsOpen={setIsOpen}
          fileName={selectedFile?.file_name}
          fileUrl={selectedFile?.url}
        />
      )}
    </div>
  );
};

export default FineTuneModelModal;
